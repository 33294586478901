// 本地缓存
export const STORAGE = {
    RJ_SPECIAL_TOKEN: 'rjIntelligenceGenerateToken',
    RJ_SPECIAL_USER_INFO: 'rjIntelligencGenerateUserInfo',
    RJ_STATE_DATA: 'rjIntelligencGenerateStateData',
}

export const CODE_CLASS = {
    // 模块key
    moduleKey: {
        imageAttackData: 1,
        videoAttackData: 2,
        faceEditData: 3,
        vivoDetectionData: 4,
        makeUpAttackData: 5,
    },
    // demo key
    demoKey: {
        imageAttackData_attackUrl: 1,
        imageAttackData_underAttackUrl: 11,
        videoAttackData_attackUrl: 2,
        videoAttackData_underAttackUrl: 22,
        faceEditData: 3,
        vivoDetectionData: 4,
        makeUpAttackData_avoid_underAttackUrl: 5,
        makeUpAttackData_impersonation_attackUrl: 52,
        makeUpAttackData_impersonation_underAttackUrl: 55,
    }
}

// state中存储的数据key
export const STATE_DATA_KEY = ['imageAttackData', 'videoAttackData', 'faceEditData', 'vivoDetectionData', 'makeUpAttackData', 'faceCreateData'];

export const CODE_CLASS_LIST = (()=>{
    let codeClassList = {};
    // 格式转换
    for(let key in CODE_CLASS){
        codeClassList[key] = [];
        for(let k in CODE_CLASS[key]){
            codeClassList[key].push({
                code: k,
                name: CODE_CLASS[key][k]
            });
        }
    }
    return codeClassList;
})();

const IMAGE_URL = 'http://saas.ruijianai.com:9311/mai/image-files/qb_create';

export const STATIC_DEMO_LIST = [`${IMAGE_URL}/faceEdit/1.png`, `${IMAGE_URL}/faceEdit/3.png`]

export const STATIC_DEMO_OBJ = {
    '0_age_-5': `${IMAGE_URL}/faceEditDemo1/1.png`,
    '0_age_5': `${IMAGE_URL}/faceEditDemo1/2.png`,
    '0_smile_1': `${IMAGE_URL}/faceEditDemo1/3.png`,
    '0_smile_3': `${IMAGE_URL}/faceEditDemo1/4.png`,
    '0_smile_5': `${IMAGE_URL}/faceEditDemo1/5.png`,
    '0_eyetype-1': `${IMAGE_URL}/faceEditDemo1/6.png`,
    '0_eyetype-0': `${IMAGE_URL}/faceEditDemo1/7.png`,
    '0_hair_color_blonde': `${IMAGE_URL}/faceEditDemo1/8.png`,
    '0_hair_color_purple': `${IMAGE_URL}/faceEditDemo1/9.png`,
    '0_hair_color_red': `${IMAGE_URL}/faceEditDemo1/10.png`,
    '0_hair_color_black': `${IMAGE_URL}/faceEditDemo1/11.png`,

    '1_age_-5': `${IMAGE_URL}/faceEditDemo2/1.jpg`,
    '1_age_5': `${IMAGE_URL}/faceEditDemo2/2.jpg`,
    '1_smile_1': `${IMAGE_URL}/faceEditDemo2/3.jpg`,
    '1_smile_3': `${IMAGE_URL}/faceEditDemo2/4.jpg`,
    '1_smile_5': `${IMAGE_URL}/faceEditDemo2/5.jpg`,
    '1_eyetype-1': `${IMAGE_URL}/faceEditDemo2/6.jpg`,
    '1_eyetype-0': `${IMAGE_URL}/faceEditDemo2/7.jpg`,
    '1_hair_color_blonde': `${IMAGE_URL}/faceEditDemo2/8.jpg`,
    '1_hair_color_purple': `${IMAGE_URL}/faceEditDemo2/9.jpg`,
    '1_hair_color_red': `${IMAGE_URL}/faceEditDemo2/10.jpg`,
    '1_hair_color_black': `${IMAGE_URL}/faceEditDemo2/11.jpg`,
}