import * as TYPE from './types';
import { CREATE } from '@/remote';
import { Message } from 'element-ui';
import {STORAGE} from '@/utils/const';
import {setLocalStorage} from '@/utils';

export const ACTIONS = {
  initData: 'initData',
  removeLoading: 'removeLoading',
  getDemo: 'getDemo',

  imageAttack: 'imageAttack',
  videoAttack: 'videoAttack',
  faceEdit: 'faceEdit',
  vivoDetection: 'vivoDetection',
  makeUpAttack: 'makeUpAttack',
  faceCreate: 'faceCreate',
}

export default {
  // 获取样例数据
  [ACTIONS.getDemo] ({state = {}, commit, dispatch}, {fileType, isOld} = {}) {
    if (state.demoData && state.demoData[fileType] && state.demoData[fileType].length) {
      return;
    }
    let method = isOld ? 'getDemo' : 'getDemoNew';
    CREATE[method]({fileType}).then(res => {
      if (res.code == 200) {
        commit(TYPE.SAVE_DEMO_DATA, {
          ...state.demoData,
          [fileType]: res.data || [],
        });
      }
    })
  },

  // 照片换脸
  [ACTIONS.imageAttack] ({state = {}, commit, dispatch}, data) {
    commit(TYPE.SAVE_IMAGE_ATTACK_DATA, {
      ...state.imageAttackData,
      loading: true,
      resultUrl: ''
    });
    CREATE.imageAttack(data).then(res => {
      commit(TYPE.SAVE_IMAGE_ATTACK_DATA, {
        ...state.imageAttackData,
        loading: false,
        resultUrl: res.code == 200 ? res.data.fileUrl : '',
      });
      if (res.code == 200) {
        Message.success('照片换脸完成！');
      }
    }, res => {
      commit(TYPE.SAVE_IMAGE_ATTACK_DATA, {
        ...state.imageAttackData,
        loading: false,
      });
    })
  },

  // 视频换脸
  [ACTIONS.videoAttack] ({state = {}, commit, dispatch}, data) {
    commit(TYPE.SAVE_VIDEO_ATTACK_DATA, {
      ...state.videoAttackData,
      loading: true,
      resultUrl: ''
    });
    CREATE.videoAttack(data).then(res => {
      commit(TYPE.SAVE_VIDEO_ATTACK_DATA, {
        ...state.videoAttackData,
        loading: false,
        resultUrl: res.code == 200 ? res.data.fileUrl : '',
      });
      if (res.code == 200) {
        Message.success('视频换脸完成！');
      }
    }, res => {
      commit(TYPE.SAVE_VIDEO_ATTACK_DATA, {
        ...state.videoAttackData,
        loading: false,
      });
    })
  },

  // 人脸编辑
  [ACTIONS.faceEdit] ({state = {}, commit, dispatch}, data) {
    commit(TYPE.SAVE_FACE_EDIT_DATA, {
      ...state.faceEditData,
      loading: true,
      resultUrl: ''
    });
    CREATE.faceEdit(data).then(res => {
      commit(TYPE.SAVE_FACE_EDIT_DATA, {
        ...state.faceEditData,
        loading: false,
        resultUrl: res.code == 200 ? res.data.fileUrl : '',
      });
      if (res.code == 200) {
        Message.success('人脸编辑完成！');
      }
    }, res => {
      commit(TYPE.SAVE_FACE_EDIT_DATA, {
        ...state.faceEditData,
        loading: false,
      });
    })
  },

  // 身份认证伪造攻击
  [ACTIONS.vivoDetection] ({state = {}, commit, dispatch}, data) {
    commit(TYPE.SAVE_VIVO_DETECTION_DATA, {
      ...state.vivoDetectionData,
      loading: true,
      videoObj: [],
    });
    CREATE.vivoDetection(data).then(res => {
      commit(TYPE.SAVE_VIVO_DETECTION_DATA, {
        ...state.vivoDetectionData,
        loading: false,
        videoObj: res.code == 200 ? res.data : {},
      });
      if (res.code == 200) {
        Message.success('人脸表情操纵完成！');
      }
    }, res => {
      commit(TYPE.SAVE_VIVO_DETECTION_DATA, {
        ...state.vivoDetectionData,
        loading: false,
      });
    })
  },

  // 化妆攻击
  [ACTIONS.makeUpAttack] ({state = {}, commit, dispatch}, {data, activeKey} = {}) {
    commit(TYPE.SAVE_MAKE_UP_ATTACK_DATA, {
      ...state.makeUpAttackData,
      [activeKey]: {
        ...state.makeUpAttackData[activeKey],
        loading: true,
        resultUrl: '',
        results: {},
        discernLoading: false,
        showDiscern: false,
      }
    });
    CREATE.makeUpAttack(data).then(res => {
      commit(TYPE.SAVE_MAKE_UP_ATTACK_DATA, {
        ...state.makeUpAttackData,
        [activeKey]: {
          ...state.makeUpAttackData[activeKey],
          loading: false,
          resultUrl: res.code == 200 ? res.data.fileUrl : '',
          results: res.code == 200 ? res.data.results : {},
        }
      });
      if (res.code == 200) {
        Message.success(activeKey == 'avoid' ? '人脸躲避攻击完成！' : '人脸冒充攻击完成！');
      }
    }, res => {
      commit(TYPE.SAVE_MAKE_UP_ATTACK_DATA, {
        ...state.makeUpAttackData,
        [activeKey]: {
          ...state.makeUpAttackData[activeKey],
          loading: false,
        }
      });
    })
  },

  // 虚拟人脸生成
  [ACTIONS.faceCreate] ({state = {}, commit, dispatch}) {
    commit(TYPE.SAVE_FACE_CREATE_DATA, {
      ...state.faceCreateData,
      loading: true,
      fileList: [],
    });
    CREATE.faceCreate().then(res => {
      commit(TYPE.SAVE_FACE_CREATE_DATA, {
        ...state.faceCreateData,
        loading: false,
        fileList: res.code == 200 ? res.data.fileUrls : [],
      });
      if (res.code == 200) {
        Message.success('虚拟人脸生成完成！');
      }
    }, res => {
      commit(TYPE.SAVE_FACE_CREATE_DATA, {
        ...state.faceCreateData,
        loading: false,
      });
    })
  },

  // 数据初始化
  [ACTIONS.initData] ({commit}) {
    commit(TYPE.CLEAR_ALL_DATA);
    setLocalStorage(STORAGE.RJ_STATE_DATA, '{}');
    localStorage.removeItem(STORAGE.RJ_SPECIAL_TOKEN);
    localStorage.removeItem(STORAGE.RJ_SPECIAL_USER_INFO);
  },

  // 移除loading状态
  [ACTIONS.removeLoading] ({commit}) {
    commit(TYPE.REMOVE_LOADING);
  },

}
