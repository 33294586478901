import Vue from 'vue'
import VueRouter from 'vue-router'
import {STORAGE} from '@/utils/const';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/Index.vue'),
    meta: {
      title: '登录',
      requireAuth:true,
    }
  },
  {
    path: '/',
    component: () => import('../views/Home/Index.vue'),
    meta: {
      title: '虚假信息智能制作平台'
    },
    children: [
      {
        path: '/',
        name: 'FaceChangeByImage',
        component: () => import('../views/Home/FaceChangeByImage.vue'),
        meta: {
          title: '虚假信息智能制作平台'
        },
      },
      {
        path: '/face-change-by-video',
        name: 'FaceChangeByVideo',
        component: () => import('../views/Home/FaceChangeByVideo.vue'),
        meta: {
          title: '虚假信息智能制作平台'
        },
      },
      {
        path: '/face-edit',
        name: 'FaceEdit',
        component: () => import('../views/Home/FaceEdit.vue'),
        meta: {
          title: '虚假信息智能制作平台'
        },
      },
      {
        path: '/identify-fake-attack',
        name: 'IdentifyFakeAttack',
        component: () => import('../views/Home/IdentifyFakeAttack.vue'),
        meta: {
          title: '虚假信息智能制作平台'
        },
      },
      {
        path: '/cosmetic-attack',
        name: 'CosmeticAttack',
        component: () => import('../views/Home/CosmeticAttack.vue'),
        meta: {
          title: '虚假信息智能制作平台'
        },
      },
      {
        path: '/virtual-face',
        name: 'VirtualFace',
        component: () => import('../views/Home/VirtualFace.vue'),
        meta: {
          title: '虚假信息智能制作平台'
        },
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((route, redirect, next) => {
  if (!localStorage.getItem(STORAGE.RJ_SPECIAL_TOKEN) && route.name !== 'Login') {
    next({
      name: 'Login',
      query: {redirect: route.fullPath}
    });
  } else {
    next();
  }
})

export default router
