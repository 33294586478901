import Remote from './request';
import imageCheck from './mockData/imageCheck';
const isMock = false;

export const LOGIN = {
  login(data){
    return Remote({
      method: 'post',
      path: `api/Login/login?password=${data.password}&username=${data.username}`,
      loading: true,
      isMock: isMock,
    });
  },
  logout(body){
    return Remote({
      method: 'post',
      path: `api/Login/logout`,
      loading: true,
      isMock: isMock,
      body,
    });
  }
}

export const CREATE = {
  getDemo(body) {
    return Remote({
      method: 'get',
      path: `api/create/getDemo`,
      loading: false,
      body,
      isMock: isMock,
    })
  },

  // 带文件夹
  getDemoNew(body) {
    return Remote({
      method: 'get',
      path: `api/create/getDemoNew`,
      loading: false,
      body,
      isMock: isMock,
    })
  },

  imageAttack(body) {
    return Remote({
      method: 'post',
      path: `api/create/imageAttack`,
      loading: false,
      isMock: isMock,
      result: imageCheck,
      showMessage: true,
      body,
    });
  },

  videoAttack(body) {
    return Remote({
      method: 'post',
      path: `api/create/videoAttack`,
      loading: false,
      isMock: isMock,
      result: imageCheck,
      showMessage: true,
      body,
    });
  },

  faceEdit(body) {
    return Remote({
      method: 'post',
      path: `api/create/faceEdit`,
      loading: false,
      isMock: isMock,
      result: imageCheck,
      showMessage: true,
      body,
    });
  },

  vivoDetection(body) {
    return Remote({
      method: 'post',
      path: `api/create/vivoDetection`,
      loading: false,
      isMock: isMock,
      result: imageCheck,
      showMessage: true,
      body,
    });
  },

  makeUpAttack(body) {
    return Remote({
      method: 'post',
      path: `api/create/makeUpAttack`,
      loading: false,
      isMock: isMock,
      result: imageCheck,
      showMessage: true,
      body,
    });
  },

  faceCreate() {
    return Remote({
      method: 'get',
      path: `api/create/faceCreate`,
      loading: false,
      isMock: isMock,
      result: imageCheck,
      showMessage: true,
    });
  },
}