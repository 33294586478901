export default {
  "code": "200",
  "msg": "操作成功",
  "data": {
    "type": null,
    "filename": null,
    "status": null,
    "content": null,
    "score": 60,
    "roi": 'http://saas.ruijianai.com:9311/mai/image-files/hebi/traceSource/2_StyleGAN2_s6604_00008679.png',
    "cover": null,
    "algSource": 'StyleGAN2',
    "appSource": '人脸合成',
    "fingerPath": null,
    "describe": null,
    "resultUrl": 'http://saas.ruijianai.com:9311/mai/image-files/hebi/hebiImage/%E6%A0%B7%E4%BE%8B1-%E7%94%9F%E6%88%90%E4%BA%BA%E8%84%B8.png.out.png',
    "format": 'jpg',
    "size": 50,
    "during": 1000,
    "resolution": '1000 * 900',
    "blurValue": null,
    "isBlur": '清晰'
  }
}