import * as TYPE from './types'
import {STORAGE, STATE_DATA_KEY} from '@/utils/const';
import {setStateDataStorage, setLocalStorage} from '@/utils';

export default {
  [TYPE.SAVE_DEMO_DATA]: (state, data = {}) => {
    state.demoData = {
      ...data,
    }
  },
  
  [TYPE.SAVE_IMAGE_ATTACK_DATA]: (state, data = {}) => {
    state.imageAttackData = {
      ...data,
    }
    setStateDataStorage('imageAttackData', state.imageAttackData);
  },

  [TYPE.SAVE_VIDEO_ATTACK_DATA]: (state, data = {}) => {
    state.videoAttackData = {
      ...data,
    }
    setStateDataStorage('videoAttackData', state.videoAttackData);
  },

  [TYPE.SAVE_FACE_EDIT_DATA]: (state, data = {}) => {
    state.faceEditData = {
      ...data,
    }
    setStateDataStorage('faceEditData', state.faceEditData);
  },

  [TYPE.SAVE_VIVO_DETECTION_DATA]: (state, data = {}) => {
    state.vivoDetectionData = {
      ...data,
    }
    setStateDataStorage('vivoDetectionData', state.vivoDetectionData);
  },

  [TYPE.SAVE_MAKE_UP_ATTACK_DATA]: (state, data = {}) => {
    state.makeUpAttackData = {
      ...data,
    }
    setStateDataStorage('makeUpAttackData', state.makeUpAttackData);
  },

  [TYPE.SAVE_FACE_CREATE_DATA]: (state, data = {}) => {
    state.faceCreateData = {
      ...data,
    }
    setStateDataStorage('faceCreateData', state.faceCreateData);
  },

  [TYPE.CLEAR_ALL_DATA]: (state) => {
    STATE_DATA_KEY.map(key => {
      state[key] = {}
    })
  },

  [TYPE.REMOVE_LOADING]: (state) => {
    STATE_DATA_KEY.map(key => {
      if (key == 'makeUpAttackData') {
        Object.keys(state[key] || {}).map(i => {
          state[key][i] = {
            ...state[key][i],
            loading: false
          }
        })
      } else {
        state[key] = {
          ...state[key],
          loading: false,
        }
      }
    })
    setLocalStorage(STORAGE.RJ_STATE_DATA, JSON.stringify(state));
  }
}
