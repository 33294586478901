import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import actions, {ACTIONS} from './actions'
import * as TYPE from './types'
import {STORAGE, STATE_DATA_KEY, CODE_CLASS} from '@/utils/const';

Vue.use(Vuex);

// demo数据
let keyList = Object.values(CODE_CLASS.moduleKey);
let demoData = keyList.reduce((obj, key) => {
  obj[key] = [];
  return obj;
}, {})

let stateDataStorage = JSON.parse(localStorage.getItem(STORAGE.RJ_STATE_DATA) || '{}');
let dataObj = STATE_DATA_KEY.reduce((obj, key) => {
  obj[key] = stateDataStorage[key] || {};
  return obj
}, {})

const State = {
  demoData,
  ...dataObj,
}

const store = new Vuex.Store({
  state: State,
  actions,
  mutations,
  getters: {}
})

export {TYPE}
export {ACTIONS}
export const state = store.state
export const commit = store.commit
export const dispatch = store.dispatch
export default store;
