const CLEAR_ALL_DATA = 'CLEAR_ALL_DATA';
const REMOVE_LOADING = 'REMOVE_LOADING';
const SAVE_DEMO_DATA = 'SAVE_DEMO_DATA';

const SAVE_IMAGE_ATTACK_DATA = 'SAVE_IMAGE_ATTACK_DATA';
const SAVE_VIDEO_ATTACK_DATA = 'SAVE_VIDEO_ATTACK_DATA';
const SAVE_FACE_EDIT_DATA = 'SAVE_FACE_EDIT_DATA';
const SAVE_VIVO_DETECTION_DATA = 'SAVE_VIVO_DETECTION_DATA';
const SAVE_MAKE_UP_ATTACK_DATA = 'SAVE_MAKE_UP_ATTACK_DATA';
const SAVE_FACE_CREATE_DATA = 'SAVE_FACE_CREATE_DATA';

export {CLEAR_ALL_DATA, REMOVE_LOADING, SAVE_DEMO_DATA, SAVE_IMAGE_ATTACK_DATA, SAVE_FACE_EDIT_DATA, SAVE_VIDEO_ATTACK_DATA, SAVE_VIVO_DETECTION_DATA, SAVE_MAKE_UP_ATTACK_DATA, SAVE_FACE_CREATE_DATA}