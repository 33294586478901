
import Vue from "vue";
import {STATIC_DEMO_LIST, STATIC_DEMO_OBJ} from '@/utils/const';
let proEnv = require('../../config/prod.env');  // 生产环境
let devEnv = require('../../config/dev.env');  // 测试环境

export default ({method, path, body, isMock=false, result, ...config}) => {
  // if (path == "api/create/faceEdit" && STATIC_DEMO_LIST.includes(body.imageTarget)) {
  //   return new Promise(resolve => {
  //     let time = (Math.random(0, 1) * 2 + 4) * 1000;
  //     setTimeout(() => {
  //       let _index = STATIC_DEMO_LIST.indexOf(body.imageTarget);
  //       let key = `${_index}_${body.attrEpudit}${(body.attrStrength || body.hairColor) ? '_' : ''}${body.attrStrength || ''}${body.hairColor || ''}`;
  //       return resolve({"code":"200","msg":"操作成功","data":{"fileUrl":STATIC_DEMO_OBJ[key]}})
  //     }, time)
  //   })
  // }

  if (isMock) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(result);
      }, 1000)
    })
  } else  {
    let baseUrl = process.env.NODE_ENV === 'production' ? proEnv.BASE_URL : (isMock ? 'mock/' : devEnv.BASE_URL);
    let request = null;
    if(method === 'get') {
      request = Vue.http({
        method: 'GET',
        url: baseUrl + path,
        params: body,
        ...config,
      });
    } else {
      request = Vue.http[method](baseUrl + path, body, {
        config,
      });
    }
    
    return request.then( res => new Promise(resolve => {
      return resolve(res.body)
    } ) );
  }
}
