import {CODE_CLASS_LIST, STORAGE} from './const';
import {dispatch, ACTIONS, state, TYPE, commit} from '@/store';
import router from "../router";

export const getCodeList = (cicName="", config={showAll: false}) => {
    if(!cicName) return [];
    // 复制，防止污染原数据
    let codeList = (CODE_CLASS_LIST[cicName] || []).slice();
    // 添加【全部】选项
    if(config.showAll){
        codeList.unshift({code: null, name: '全部'});
    }
    return codeList;
}

export const setLocalStorage = (key, data) => {
    localStorage.setItem(key, data);
}

// 设置本地存储的state值
export const setStateDataStorage = (key, data) => {
    let rjStateData = JSON.parse(localStorage.getItem(STORAGE.RJ_STATE_DATA) || '{}');
    rjStateData[key] = {...data};
    setLocalStorage(STORAGE.RJ_STATE_DATA, JSON.stringify(rjStateData));
}

// 跳转登录页面并且重置数据
export const goLoginPageAndInitData = (redirect = '') => {
    router.replace({
        name: 'Login',
        query: {redirect}
    });
    dispatch(ACTIONS.initData);
}

// 下载图片
export const downloadImage = (url, name = 'downloadimage') => {
    let canvas = document.createElement('canvas');
    let img = document.createElement('img');
    img.setAttribute("crossOrigin", 'Anonymous');
    img.src = `${url}?time=${new Date().getTime()}`;
    img.onload = function (e) {
    canvas.width = img.width;
    canvas.height = img.height;
    let context = canvas.getContext('2d');
    context.drawImage(img, 0, 0, img.width, img.height);
    canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
    canvas.toBlob((blob) => {
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
    }, "image/png");
    // 这里将jpeg格式改成png，因为jpeg在window上会变成jfif格式（原因未知）
    }
}

// 下载视频
export const downloadVideo = (url, name = 'downloadvideo') => {
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'arraybuffer'; // 返回类型blob
    xhr.onload = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        let blob = this.response;
        // 转换一个blob链接
        let u = window.URL.createObjectURL(new Blob([blob],{ type: 'video/mp4' }))
        let link = document.createElement('a');
        link.download = name;
        link.href = u;
        link.style.display = 'none'
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    };
    xhr.send()
}