
import Vue from 'vue'
import { Loading, Message } from 'element-ui';
import {dispatch, ACTIONS, state, TYPE, commit} from '@/store';
import {STORAGE} from '@/utils/const';
import router from "../router";
import { goLoginPageAndInitData } from '@/utils';
import { message } from '@/utils/message';

export default function () {
  // 请求拦截
  Vue.http.interceptors.push((req, next) => {
    let rjSpecialToken = localStorage.getItem(STORAGE.RJ_SPECIAL_TOKEN);
    if (rjSpecialToken) {
      req.headers.set('token', rjSpecialToken); //设置请求头token
    }
    let config = req.method == 'GET' ? req : req.config;
    let { loading = false, showMessage = true } = config || {};
    //显示加载
    const pageLoading = loading ? Loading.service({
      fullscreen: true,
      background: 'rgba(0,0,0,0.7)'
    }) : '';

    // 删除config
    req.body && (delete req.body.config);

    // continue to next interceptor
    next((response) => {
      pageLoading && pageLoading.close();
      let res = response.body;
      if (res.code != 200) {
        if (res.code == 401) {//token 401 失效 
          goLoginPageAndInitData(window.location.pathname);
        }
        showMessage && message.error(res.msg || '请求失败');
      }
      return response;
    });
  });
}


