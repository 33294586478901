import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueResource from 'vue-resource';
import interceptors from '@/remote/interceptors';
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import "./assets/less/base.less";

Vue.use(ElementUI);
Vue.use(VueResource);
Vue.config.productionTip = false;
Vue.prototype.$EventBus = new Vue()

// 请求处理
interceptors();

// 路由拦截
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title || '虚假信息智能制作平台';
  }
  next();
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
