<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import store, {dispatch, ACTIONS, state, TYPE, commit} from '@/store';

export default {
  name: "app",
  components: {
  },
  data(){
    return {
    };
  },
  created() {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  mounted() {
  },
  destroyed() {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  methods: {
    // 刷新或关闭页面时，需要重置state和localStroage中存储的loading，不然会导致页面一直卡在loading状态
    beforeunloadFn(e) {
      dispatch(ACTIONS.removeLoading);
    }
  }
};
</script>

<style lang="less">
  @import './assets/less/const';
</style>